import {
  PlusOutlined,
  RightSquareOutlined,
  DownSquareFilled,
} from '@ant-design/icons';
import {
  Button, Col, Divider, Input, Form, Modal, Row,
  Typography, message, Tooltip, Tag, Empty
} from 'antd';
import { useTranslation } from 'react-i18next';
import { ColumnsType, ColumnType } from 'antd/es/table';
import React from 'react';

import { useNavigation } from '@App/settings/NavigationProvider';
import { useKeycloak } from '@App/settings/keycloak';
import { handler } from '@App/settings/ApiHandler';
import { ExpandRowContent } from '@App/components/tables/ExpandableRow';
import { renderValue, ValueType } from '@App/components/tables/TableHelpers';
import { ApplicationState } from '@App/settings/StateManager';
import {formatDateTime, formatDateValue} from '@App/components/helpers';
import { statusItems } from '@App/components/buttons/StatusButton';
import { ActionTypes } from '@App/settings/reducers';
import AppTable from '@App/components/tables/TableComponent';
import DynamicFormModal from '@App/components/forms/DynamicFormModal';
import PageWrapper from '@App/components/wrappers/PageWrapper';
import KC from "@App/@types/keycloakTypes";
import {wrapWithTooltip} from "@App/components/wrapWithTooltip";
import { Language } from '@AppRoot';


const tableName = 'userArt30Table';

type Res = Responses.Default & {
  faculties: Responses.FacultyType[] | API.FacultyItem[];
  forms: Form.FormType[];
  // projects: Responses.ProjectData[];
  items: Responses.TTRData[];
  ttr?: Responses.TTRData[];
  all?: Responses.TTRData[];
}

const getTtrData = (kc: KC.KCType) => handler<Res>({
  method: 'GET',
  path: '/v3/ttr/',
}, kc!)

type TtrRes = {success?: string, error?: string, counts?: number}
const postTTRData = (
  kc: KC.KCType,
  payload: API.PayloadType,
  path: string = '/v2/fill/ttr/',
) => handler<TtrRes>({
  method: 'POST', path, payload
}, kc! );

const updateData = (
  kc: KC.KCType,
  payload: API.PayloadType,
  uuid: string,
) => handler<TtrRes>({
  method: 'PUT',
  path: `/v1/ttr/${uuid}`,
  payload
}, kc! );


export const UserTTR: React.FC = () => {
  const [ action, setAction ] = React.useState<API.ActionType>('create');
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false);
  const [ modalOpen, setModalOpen ] = React.useState<boolean>(false);
  
  const [search, setSearch] = React.useState<string>('');
  
  const [ fFields, setFFields ] = React.useState<Form.FormType['fields']|API.Form['fields']>([]);
  
  const [ttrData, setTtrData] = React.useState<Responses.TTRData[]>([]);
  const [faculties, setFaculties] = React.useState<Array<Responses.FacultyType|API.FacultyItem>>([]);
  const [formsData, setFormsData] = React.useState<Array<Form.FormType|API.Form>>([]);
  
  const selectedForm = React.useRef<Form.FormType>();
  const selectedItem = React.useRef<Responses.TTRData>();
  const [searchedData, setSearchedData] = React.useState<Responses.TTRData[]>([]);
  const { keycloak } = useKeycloak();
  const [ form ] = Form.useForm();
  const { t, i18n } = useTranslation();
  const { handleNavigate: nav } = useNavigation();
  
  const {state, dispatch} = React.useContext(ApplicationState);
  
  const compactMode = state.tables?.[tableName]?.compactMode;

  const get = () => {
    const abortController = new AbortController();
    setIsLoading(true);

    getTtrData(keycloak!)
      .then((data) => {
        if (data.forms) {
          setFormsData(data.forms);
          // formsData.current = data.forms;
        }
        if (data.faculties) {
          setFaculties(data.faculties);
        }
        if (data.items) {
          // ttrData.current = data.items;
          setTtrData(data.items);
        }
        if (data.warning) {
          message.warning(data.warning)
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    
    return () => {
      abortController.abort();
    };
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setModalOpen(false);
  };

  const handleDataDelete = (e?: any) => {
    console.log(e);
    message.success(t(e.success))
    setAction('delete');
    setModalOpen(false);
    get();
  };

  const handleDataUpdate = (item: Responses.TTRData) => {
    const selectedDataValues = ttrData.find(i => i.uuid == item.uuid);
    if (selectedDataValues) {
      setAction('update');
      selectedItem.current = selectedDataValues;
      setModalOpen(true);
    }
  };

  type ExpectedFormValues = {
    faculty_code: string;
    [k: string]: string | string[] | object
  }
  const handlePost = (values: ExpectedFormValues) => {
    setIsLoading(true);

    if (selectedItem.current) {
      const payload = {
        action,
        payload: {
          updated_at: selectedItem.current?.updated_at,
          form: selectedForm.current,
          uuid: selectedItem.current?.uuid,
          faculty_code: values?.faculty_code,
          form_data: values
        }
      }
      updateData(
        keycloak!,
        payload,
        selectedItem.current.uuid
      ).then((res) => {
        // console.log(res)
        if (res.success) {
          message.success(t('forms.success.'+res.success))
        }
        setModalOpen(false);
        get();
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
  };

  const handleEntityConfirms = (values: object) => {
    setIsLoading(true);
    const payload = {
      action: 'confirm',
      ...values
    } as API.PayloadType;

    updateData(
      keycloak!,
      payload,
      `/v3/ttr/confirm`
    ).then((res) => {
      // console.log(res)
      if (res.success) {
        message.success(t(`forms.success.${res.success}`, {count: res.counts}))
        get();
      }
      setModalOpen(false);
      get();
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const loadTableConfig = () => {
    dispatch({ type: ActionTypes.Load_table, payload: { tableName } });
    // console.info({ type: ActionTypes.Load_table }, tableName);
  };

  React.useEffect(() => {
    if (!state.tables[tableName]) {
      loadTableConfig();
    };
    const cleanup = get();
    
    return () => {
      cleanup();
    };
  }, []);


  React.useEffect(() => {
    if (formsData.length > 0) {
      const ttrForm = formsData.find(f => f.slug == 'ttr');
      if (ttrForm) {
        setFFields(ttrForm.fields);
      } else {
        message.warning('Form not found');
      }
      
    }
  }, [formsData]);


  const columns = React.useMemo(() => {

    const firstColumns: ColumnsType<Responses.TTRData> = [
      {
        key: 'faculty_code',
        title: t('table.column.label.faculty'),
        dataIndex: 'faculty_code',
        // fixed: lockedColumns.faculty_code,
        filterSearch: true,
        filterMultiple: true,
        filters: faculties.map( faculty => ({
          key: faculty.code,
          text: `${faculty.code} ${faculty.labels[i18n.language as 'et'|'en']} `,
          value: faculty.code,
        })),
        // defaultSortOrder: 'ascend',
        onFilter: (value, record) => record.faculty_code == value,
        // sortOrder: sortedInfo?.columnKey === 'faculty_code' ? sortedInfo.order : null,
        sorter: {
          compare: (a, b) => a.faculty_code.localeCompare(b.faculty_code),
        },
        width: 150,
        render: (fCode: number|string, record) => {
          const faculty = faculties.find((f) => f.code == fCode);
          return (
            <Tooltip title={fCode}>
              {faculty?.labels?.[i18n.language as 'en'|'et'] ?? fCode}
            </Tooltip>
          );
        }
      },
      {
        key: 'art30_eesmark',
        // title: 'Töötlemise eesmärk',
        title: t('table.column.label.art30_eesmark'),
        dataIndex: ['form_data', 'art30_eesmark'],
        ellipsis: compactMode,
        sorter: {
          compare: (a, b) => {
            let valueA = a.form_data['art30_eesmark'];
            let valueB = b.form_data['art30_eesmark'];
            
            if (Array.isArray(valueA)) {
              valueA = valueA.join(', ');
            }
            if (Array.isArray(valueB)) {
              valueB = valueB.join(', ');
            }
            return valueA.trim().localeCompare(valueB.trim());
          },
        },
        filterSearch: true,
        // fixed: lockedColumns.faculty_code,
        filterMultiple: true,
        defaultSortOrder: 'ascend',
        onFilter: (value, record) => record.faculty_code == value,
        // sortOrder: sortedInfo?.columnKey === 'faculty_code' ? sortedInfo.order : null,
        width: 220,
        render: (fCode: number|string, record) => {
          const faculty = faculties.find(f => f.code == fCode);
          return (
            <Tooltip title={fCode}>
              {faculty ? faculty.labels[i18n.language as 'en'|'et'] : fCode }
            </Tooltip>
          );
        }
      },
    ];

    const lastColumns: ColumnsType<Responses.TTRData> = [
      {
        key: 'created_at',
        dataIndex: 'created_at',
        title: t('table.column.created'),
        align: 'center',
        width: 100,
        // fixed: lockedColumns.created_at,
        // sortOrder: sortedInfo?.columnKey === 'created_at' ? sortedInfo.order : null,
        sorter: {
          compare: (a, b) => a.created_at - b.created_at,
        },
        ellipsis: false,
        render: (createdAt) => (
          <Tag
            bordered={false}
            title={formatDateTime(createdAt, true,  'DD.MM.YYYY - hh:mm Z') }
          >
            { formatDateTime(createdAt, true, 'DD.MM.YYYY') }
          </Tag>
        ),
      },
      {
        key: 'updated_at',
        dataIndex: 'updated_at',
        title: t('table.column.lastUpdated'),
        align: 'center',
        width: 130,
        // fixed: lockedColumns.updated_at,
        // sortOrder: sortedInfo?.columnKey === 'updated_at' ? sortedInfo.order : null,
        sorter: {
          compare: (a, b) => (a.updated_at ?? 0) - (b.updated_at ?? 0),
        },
        render: (updatedAt: number | null) => updatedAt ? (
          <Tag
            bordered={false}
            title={formatDateTime(updatedAt, true,  'DD.MM.YYYY - hh:mm Z') }
          >
            { formatDateTime(updatedAt, true,  'DD.MM.YYYY') }
          </Tag>
        ) : '-'
      },
      {
        key: 'status',
        // fixed: lockedColumns.status,
        dataIndex: 'status',
        align: 'center',
        width: 100,
        title: t('table.column.label.status'),
        filters: statusItems.map(
          (i, idx) => ({ key:idx, text: i.label, value: i?.key})
        ),
        onFilter: (value, record) => record.status == value,
        render: (status) => {
          const color =
            status === 'draft' ? 'gold'
            : status === 'active'? 'green'
            : status === 'confirm'? 'green'
            : status === 'deleted' ? 'red'
            : 'cyan';
          return (
            <Tag color={color}>
              { typeof status == 'string'
                ? t(`settings.status.${status}`, status.toUpperCase())
                : '-'
              }
            </Tag>
          )
        },
      },
    ];

    const extraColumns = fFields
    .filter(f => f.name !== 'art30_eesmark')
    .map((field, fieldIdx) => {
      let filterProps:ColumnType<Responses.TTRData> = {
        key: field.name,
        title: wrapWithTooltip({
          translationKey: (
              <Typography>
                <Typography.Paragraph>
                  <Typography.Text type={'secondary'}>{t('table.column.label.translationKeys')} </Typography.Text>
                  {`"forms.label.${field.name}"`}
                </Typography.Paragraph>
                <Typography.Paragraph>
                  <Typography.Text type={'secondary'}>{t('forms.label.translationFallback', 'fallback value')} </Typography.Text>
                  {JSON.stringify(field.label)}
                </Typography.Paragraph>
              </Typography>
          ),
          children: <>{t(`forms.label.${field.name}`, field.label)}</>,
        }),
        ellipsis: compactMode,
        dataIndex: ['form_data', field.name],
        width: fieldIdx < 7 ? 270 : 200,
        sorter: undefined,
        // fixed: lockedColumns[field.name],
      };

      if (['date', 'date-time', 'date-range', 'date-range-time'].includes(field.type)) {
        return {
          ...filterProps,
          // filtered: visibleColumns?.includes(field.name) ?? true,
          render: (value: string | string[] | null) => formatDateValue(value),
        };
      }

      if (field.settings.options?.length) {
        filterProps.filterMode = 'tree';
        filterProps.filterMultiple = true;
        filterProps.filters = field.settings.options.map((v,vIdx) => ({
          key: vIdx, text: v.label[i18n.language as Language], value: v.value
        }));
        filterProps.onFilter = (value, record) => {
          if (record.form_data && field.name in record.form_data) {
            const item = record.form_data[field.name];
            if (item === null || item === undefined) return false;
            
            else if (typeof item == "string") {
              return item == value+'' || item.includes(value+'');
            }
            else if (Array.isArray(item)) {
              return item.includes(value+'') || item.some(v=> v.includes(value+''));
            }
            console.info('no filter match', field.name)
          }
          return false;
        }
      }

      return {
        // sortOrder: state.tables[tableName].sorter?.columnKey === field.name ? state.tables[tableName].sorter!.order : null,
        ...filterProps,
        render: (value: ValueType) => renderValue(value, `${compactMode ? 'is' : 'non'}-compact`, compactMode, field, i18n.language as Language),
      };
    });

    return [
      ...firstColumns,
      ...extraColumns,
      ...lastColumns
    ];
  }, [fFields, t, compactMode, i18n.language]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setSearch(e.target.value);
  };

  React.useEffect(() => {
    if (!search) {
      setSearchedData(ttrData);
    }
    else if (search.length > 3) {
      const newFilteredList = ttrData.filter((d) =>
        JSON.stringify(d.form_data)
            .toLowerCase()
            .includes(search.toLowerCase())
      )
      setSearchedData(newFilteredList);
    }
  }, [search, ttrData]);

  return (
    <>
      <PageWrapper>
        <Row style={{ marginBottom: 6 }}>
          <Col span={24}>
            <Typography.Title level={2} >
              { t("links.TTRlist") }
            </Typography.Title>
          </Col>
        </Row>
        <Row justify='space-between'>
          <Col>
            <Input.Search
              allowClear
              placeholder={t("forms.placeholders.search")}
              value={search}
              onSearch={handleSearch}
              onChange={handleSearchChange}
              // prefix={<SearchOutlined />}
              />
          </Col>
          <Col >
            <Button
              type='link'
              shape='round'
              className='btn-border'
              onClick={() => nav('/forms')}
            >
              {t('pages.nav.forms')}
            </Button>
          </Col>
        </Row>
      </PageWrapper>
      
      <AppTable<Responses.TTRData>
        rowKey='uuid'
        isSelectable={true}
        tableName={tableName}
        tableTitle='table.title.projects.art30'
        isAdmin={false}
        loading={isLoading}
        dataSource={searchedData}
        extraColumns={columns}
        sticky={{ offsetHeader: 64 }}
        scroll={{ x: 300 }}
        onConfirm={handleEntityConfirms}
        // onConfigChange={({sorter})}
        rowClassName={compactMode ? ' compact-row ' : ''}
        expandable={{
          rowExpandable: (record) => record.form_data && Object.keys(record.form_data).length > 0,
          expandedRowRender: (record) => (
            <ExpandRowContent
              key={record.uuid}
              record={record}
              onEdit={handleDataUpdate}
              onAdminEdit={console.log}
              formFields={fFields}
              isAdmin={false}
              // onDelete={handlerDelete}
            />
          ),
          columnWidth: 48,
          expandIcon: ({expanded, onExpand, record}) => expanded
            ? <DownSquareFilled onClick={(e)=> onExpand(record, e)} style={{fontSize: '140%'}} />
            : <RightSquareOutlined onClick={(e)=> onExpand(record, e)} style={{fontSize: '140%' }} />
        }}
        style={{ maxWidth: '100%'}}
        customEmptyText={(
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            // image={emptyImg}
            imageStyle={{ height: 60 }}
            description={t('table.texts.emptyText')}
          >
            <Button
              shape='round'
              // type="primary"
              icon={<PlusOutlined />}
              onClick={() => nav('/forms/ttr')}
            >
            {t('links.TTRlist.add')}
            </Button>
          </Empty>
        )}
      />

      <Modal
        title={t(`forms.titles.${action == 'update' ? 'update' : 'new'}Article30`)}
        open={modalOpen}
        // onOk={handlePost}
        okText={t(`forms.btn.${action === 'new' ? 'create' : 'update'}`)}
        cancelText={t('forms.btn.cancel')}
        confirmLoading={isLoading}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <Typography>
          {selectedForm.current?.description}
        </Typography>
        
        <Divider />

        <DynamicFormModal
          formData={formsData[0] ?? { fields: [] }}
          initialValues={selectedItem.current}
          onFinish={handlePost}
          onCancel={handleCancel}
          facultyOptions={faculties}
          action={action}
          onDelete={handleDataDelete}
          children={undefined}
        />

      </Modal>

    </>
  );
};

import {
  MoreOutlined,
  ReloadOutlined,
  PlusOutlined,
  DownSquareFilled,
  RightSquareOutlined,
  SearchOutlined,
  FilterFilled,
  ClearOutlined,
  FilterTwoTone,
  FilterOutlined,
} from '@ant-design/icons';
import {
  Button, Col, Divider, Dropdown, Input, Form, Modal, Row, Space, Typography, Menu, Checkbox, message, notification, Tooltip, Tag, theme, DatePicker, Radio, Select, Collapse, Flex, Drawer, Badge
} from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { ApplicationState } from '@App/settings/StateManager';
import { AdminDataUpdate } from '@App/components/forms/AdminDataUpdate';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { useKeycloak } from '@App/settings/keycloak';
import { handler } from '@App/settings/ApiHandler';
import { ExpandRowContent } from '@App/components/tables/ExpandableRow';
import { renderValue } from '@App/components/tables/TableHelpers';
import { formatDateTime } from '@App/components/helpers';
import { statusItems } from '@App/components/buttons/StatusButton';
import AppTable from '@App/components/tables/TableComponent';
import DynamicFormModal from '@App/components/forms/DynamicFormModal';
import PageWrapper from '@App/components/wrappers/PageWrapper';
import { ActionTypes } from '@App/settings/reducers';
import KC from "@App/@types/keycloakTypes";
import {wrapWithTooltip} from "@App/components/wrapWithTooltip"
import { useNavigation } from '@App/settings/NavigationProvider';
import { Language } from '@AppRoot';


type Res = Responses.Default & {
  faculties: Responses.FacultyType[] | API.FacultyItem[];
  forms: Form.FormType[];
  // projects: Responses.ProjectData[];
  items: Responses.TTRData[];
  ttr?: Responses.TTRData[];
  all?: Responses.TTRData[];
}

const getTtrData = (kc: KC.KCType) => handler<Res>({
  method: 'GET',
  path: '/v3/ttr/',
}, kc!)

const postTTRData = (
  kc: KC.KCType,
  payload: API.PayloadType,
  path: string = '/v2/fill/ttr/',
) => handler<{success?: string, error?: string }>({
  method: 'POST', path, payload
}, kc! );

const updateArt30Data = (
  kc: KC.KCType,
  payload: API.PayloadType,
  uuid: string,
) => handler<Responses.Default>({
  method: 'PUT',
  path: `/v1/ttr/${uuid}`,
  payload
}, kc! );

type Props = {
  showStatus?: boolean;
  showFaculties?: boolean;
};

const tableName = 'adminArt30Table';

export const AdminArt30Projects: React.FC<Props> = () => {
  const [ action, setAction ] = React.useState<API.ActionType>('new');
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false);
  const [ modalOpen, setModalOpen ] = React.useState<boolean>(false);
  const [ filtersOpen, setFiltersOpen ] = React.useState<boolean>(false);
  const [ adminModalOpen, setAdminModalOpen ] = React.useState<boolean>(false);
  
  const {state, dispatch} = React.useContext(ApplicationState);
  
  const [fFields, setFFields] = React.useState<Form.FormType['fields']|API.Form['fields']>([]);
  const [ttrData, setTtrData] = React.useState<Responses.TTRData[]>([]);
  const [faculties, setFaculties] = React.useState<Responses.FacultyType[]|API.FacultyItem[]>([]);
  const [formsData, setFormsData] = React.useState<Array<Form.FormType|API.Form>>([]);
  
  const selectedForm = React.useRef<Form.FormType>();
  const selectedItem = React.useRef<Responses.TTRData>();

  const [search, setSearch] = React.useState<string>('');
  const [isSearchFocused, setIsSearchFocused] = React.useState<boolean>(false);
  const [searchedData, setSearchedData] = React.useState<Responses.TTRData[]>([]);
    
  const { keycloak } = useKeycloak();
  const { t, i18n } = useTranslation();
  const { token } = theme.useToken();
  const { handleNavigate: nav } = useNavigation();
  
  const compactMode = state.tables?.[tableName]?.compactMode;
  const hasFilters = state.tables?.[tableName]?.filters;

  const get = () => {
    const abortController = new AbortController();
    setIsLoading(true);

    getTtrData(keycloak!)
      .then((data) => {
        if (data.forms) {
          setFormsData(data.forms);
        }
       
        if (data.faculties) {
          setFaculties(data.faculties);
        }
        if (data.all) {
          setTtrData(data.all);
        }
        // console.log(data)
      })
      .finally(() => {
        setIsLoading(false);
      });
    
    return () => {
      abortController.abort();
    };
  };

  const handleCancel = () => {
    setModalOpen(false);
    setAdminModalOpen(false);
  };
  
  const handleDataUpdate = (item: Responses.TTRData) => {
    const selectedDataValues = ttrData.find(i => i.uuid == item.uuid);

    if (selectedDataValues) {
      setAction('update');
      selectedItem.current = selectedDataValues;
      setModalOpen(true);
    }
  };

  const handleDataDelete = (e?: any) => {
    // console.log(e);
    message.success(t(e.success))
    setAction('delete');
    setModalOpen(false);
    get();
  }

  const moreMenuItems = [{
    key: "refresh",
    icon: <ReloadOutlined />,
    label: t('buttons.refresh'),
    onClick: () => get()
  },{
    key: "new",
    icon: <PlusOutlined />,
    label: t('links.new.data'),
    onClick: () => nav('/ttr/new'),
  }];


  const handlePost = (values: object) => {
    setIsLoading(true);

    if (selectedItem.current) {
      const payload = {
        form: selectedForm.current,
        uuid: selectedItem.current?.uuid,
        faculty_code: selectedItem.current.faculty_code,
        form_data: values
      }
      postTTRData( keycloak!, {
        action, payload
      }, `/v3/ttr/${selectedItem.current?.uuid}`)
        .then((res) => {
          console.log(res)
          if (res.success) {
            // notification.success(t(
            //   res.success ?
            //     ? `forms.success.${res.success}`
            //     : 'forms.success.default.update'
            // ))
          }
          get();
          setModalOpen(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    
  };

  const updateTableConfig = (data: App.TableMeta) => {
    dispatch({ type: ActionTypes.Update_table, payload: { tableName, data } });
    console.info({ type: ActionTypes.Update_table, tableName, data });
  };

  const loadTableConfig = () => {
    dispatch({ type: ActionTypes.Load_table, payload: { tableName } });
    console.info({ type: ActionTypes.Load_table }, tableName);
  };

  React.useEffect(() => {
    if (!state.tables[tableName]) {
      loadTableConfig();
    }
    const cleanup = get();

    return () => {
      cleanup();
    };
  }, []);


  React.useEffect(() => {
    if (formsData.length > 0) {
      const ttrForm = formsData.find(f => f.slug == 'ttr');
      if (ttrForm) {
        setFFields(ttrForm.fields);
      } else {
        message.warning('Form not found');
      }
      
    }
  }, [formsData]);

  const handleEntityConfirms = (values: {newStatus: string, items: React.Key[]|undefined}) => {
    setIsLoading(true);
    const payload = {
      action: 'confirm',
      ...values
    } as API.PayloadType;
    // console.log(values);
    postTTRData( keycloak!, payload, `/v3/ttr/confirm`)
      .then((res) => {
        if (res.success) {
          message.success(t('forms.success.'+res.success))
        }
        setModalOpen(false);
        get();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEntityChange = (item: Responses.TTRData) => {
    const selectedDataValues = ttrData.find(i => i.uuid == item.uuid);

    if (selectedDataValues) {
      selectedItem.current = selectedDataValues;
      setAction('update');
      setModalOpen(true);
    } else {
      message.warning('Data entity was not found.')
    }
  };
  const handleAdminEntityChange = (item: Responses.TTRData) => {
    const selectedDataValues = ttrData.find(i => i.uuid == item.uuid);

    if (selectedDataValues) {
      selectedItem.current = selectedDataValues;
      setAction('update');
      setAdminModalOpen(true);
    } else {
      message.warning('Data entity was not found.')
    }
  };
  
  const handleFacultyFilterChange = (selectedValues: string[]) => {
    if (Array.isArray(selectedValues)) {
      const prev = state.tables[tableName];
      updateTableConfig({
        ...prev,
        filters: {
          ...prev.filters,
          "faculty_code": selectedValues,
        }
      })
    }
  }

  const handleStatusFilterChange = (selectedValues: string[]) => {
    if (Array.isArray(selectedValues)) {
      const prev = state.tables[tableName];
      updateTableConfig({
        ...prev,
        filters: {
          ...prev.filters,
          "status": selectedValues,
        }
      })
    }
  };
 
  const handleDateRangeFilterChange = ([start, end]: [string, string]) => {
    console.log('start:', start, ' to:', end)
  };

  const facultyFilterOptions = faculties.map( faculty => ({
    key: faculty.code,
    text: `${faculty.code} ${faculty.labels[i18n.language as 'et'|'en']} `,
    value: faculty.code,
  }));
  console.log(faculties)
  const columns = React.useMemo(() => {

    const firstColumns: ColumnsType<Responses.TTRData> = [
      {
        key: 'faculty_code',
        title: t('table.column.label.faculty'),
        dataIndex: 'faculty_code',
        // fixed: lockedColumns.faculty_code,
        ellipsis: compactMode,        
        filterSearch: true,
        filterMultiple: true,
        filters: faculties.map( faculty => ({
          key: faculty.code,
          text: faculty.labels[i18n.language as 'et'|'en'] !== undefined
            ? `${faculty.code} ${faculty.labels[i18n.language as 'et'|'en']} `
            : `${faculty.code} ${faculty.name} `,
          value: faculty.code,
        })),
        // defaultSortOrder: 'ascend',
        onFilter: (value, record) => record.faculty_code == value || record.faculty_code.startsWith(value+''),
        // sortOrder: sortedInfo?.columnKey === 'faculty_code' ? sortedInfo.order : null,
        sorter: {
          compare: (a, b) => a.faculty_code.localeCompare(b.faculty_code),
        },
        width: 150,
        render: (fCode: number|string, record) => {
          const faculty = faculties.find((f) => f.code == fCode);
          return (
            <Tooltip title={fCode}>
              {faculty?.labels?.[i18n.language as 'en'|'et'] ?? fCode}
            </Tooltip>
          );
        }
      },
      {
        key: 'art30_eesmark',
        title:'Töötlemise eesmärk',
        dataIndex: ['form_data', 'art30_eesmark'],
        ellipsis: compactMode,        
        sorter: {
          compare: (a, b) => {
            let valueA = a.form_data['art30_eesmark'];
            let valueB = b.form_data['art30_eesmark'];
            
            if (Array.isArray(valueA)) {
              valueA = valueA.join(', ');
            }
            if (Array.isArray(valueB)) {
              valueB = valueB.join(', ');
            }
            return valueA.trim().localeCompare(valueB.trim());
          },
        },
        // filterSearch: true,
        // // fixed: lockedColumns.faculty_code,
        // filterMultiple: true,
        // filters: faculties.map( faculty => ({
        //   key: faculty.code,
        //   text: `${faculty.code} ${faculty.labels[i18n.language as 'et'|'en']} `,
        //   value: faculty.code,
        // })),
        defaultSortOrder: 'ascend',
        onFilter: (value, record) => record.faculty_code == value,
        // sortOrder: sortedInfo?.columnKey === 'faculty_code' ? sortedInfo.order : null,
        width: 220,
        render: (fCode: number|string, record) => {
          const faculty = faculties.find(f => f.code == fCode);
          return (
            <Tooltip title={fCode}>
              {faculty ? faculty.labels[i18n.language as 'en'|'et'] : fCode }
            </Tooltip>
          );
        }
      },
    ];

    const lastColumns: ColumnsType<Responses.TTRData> = [
      {
        key: 'created_at',
        dataIndex: 'created_at',
        title: t('table.column.created'),
        align: 'center',
        width: 100,
        // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        //   <div style={{ padding: 8 }}>
        //     <DatePicker.RangePicker
        //       onChange={(e) => {
        //         setSelectedKeys(e ? [e.format('YYYY-MM-DD')] : []);
        //         confirm();
        //       }}
        //       onOpenChange={(open) => {
        //         if (!open) {
        //           confirm();
        //         }
        //       }}
        //     />
        //     <button onClick={() => console.log('clear')}>Reset</button>
        //   </div>
        // ),
        // onFilter: (value, record) => {
        //   const recordDate = dayjs(record.created_at).format('YYYY-MM-DD');
        //   return recordDate === value;
        // },
        // filterIcon: (filtered) => (
        //   <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        // ),
        // fixed: lockedColumns.created_at,
        // sortOrder: sortedInfo?.columnKey === 'created_at' ? sortedInfo.order : null,
        sorter: {
          multiple: 1,
          compare: (a, b) => a.created_at - b.created_at,
        },
        ellipsis: compactMode,
        // render: (createdAt) => formatDateTime(createdAt, true),
        render: (createdAt) => (
          <Tag
            bordered={false}
            title={formatDateTime(createdAt, true, 'DD.MM.YYYY - hh:mm:ss Z')}
          >
            {formatDateTime(createdAt, true, 'DD.MM.YYYY')}
          </Tag>
        )
      },
      {
        key: 'updated_at',
        dataIndex: 'updated_at',
        title: t('table.column.lastUpdated'),
        align: 'center',
        width: 130,
        // fixed: lockedColumns.updated_at,
        // sortOrder: sortedInfo?.columnKey === 'updated_at' ? sortedInfo.order : null,
        sorter: {
          // multiple: 2,
          compare: (a, b) => (a.updated_at ?? 1) - (b.updated_at ?? 1),
        },
        // render: (updatedAt: number | null) => formatDateTime(updatedAt, true),
        render: (updatedAt: number | null) => updatedAt ? (
          <Tag
            bordered={false}
            title={formatDateTime(updatedAt, true, 'DD.MM.YYYY - hh:mm:ss Z')}
          >
            {formatDateTime(updatedAt, true, 'DD.MM.YYYY')}
          </Tag>
        ) : '-'
      },
      {
        key: 'version',
        title: t('table.column.label.version'),
        dataIndex: 'version',
        align: 'center',
        width: 90,
        sorter: {
          compare: (a, b) => a.version - b.version,
          // multiple: 3,
        },
      },
      {
        key: 'status',
        // fixed: lockedColumns.status,
        dataIndex: 'status',
        title: t('table.column.label.status'),
        align: 'center',
        width: 100,
        filters: statusItems.map(
          (i, idx) => ({ key:idx, text: t(`settings.status.${i.label.toLowerCase()}`), value: i?.key})
        ),
        sorter: {
          // multiple: 3,
          compare: (a, b) => a.status.localeCompare(b.status),
        },
        onFilter: (value, record) => record.status == value,
        render: (status) => {
          const color =
            status === 'draft' ? 'gold'
            : status === 'active'? 'green'
            : status === 'confirm'? 'green'
            : status === 'deleted' ? 'red'
            : 'cyan';
          return (
            <Tag color={color}>
              { typeof status == 'string'
                ? t(`settings.status.${status}`, status.toUpperCase())
                : '-'
              }
            </Tag>
          )
        },
      },
    ];

    const extraColumns = fFields
    .filter(f => f.name !== 'art30_eesmark')
    .map(
      (field, fieldIdx) => {
      let filterProps: ColumnType<Responses.TTRData> = {
        key: field.name,
        // visible: state.tables?.[tableName].hiddenColumns.includes(field.name) ?? false,
        // title: field.label,
        title: wrapWithTooltip({
          translationKey: (
              <Typography>
                <Typography.Paragraph>
                  <Typography.Text type={'secondary'}>{t('table.column.label.translationKeys')} </Typography.Text>
                  {`"forms.label.${field.name}"`}
                </Typography.Paragraph>
                <Typography.Paragraph>
                  <Typography.Text type={'secondary'}>{t('forms.label.translationFallback', 'fallback value')} </Typography.Text>
                  {JSON.stringify(field.label)}
                </Typography.Paragraph>
              </Typography>
          ),
          children: <>{t(`forms.label.${field.name}`, field.label)}</>,
        }),
        ellipsis: compactMode,
        dataIndex: ['form_data', field.name],
        width: fieldIdx < 7 ? 270 : 200,
        sorter: undefined,
        // fixed: lockedColumns[field.name],
      };

      if (field.settings.options?.length) {
        filterProps.filterMode = 'tree';
        filterProps.filterMultiple = true;
        filterProps.filters = field.settings.options.map((v,vIdx) => ({
          key: vIdx, text: v.label[i18n.language as Language], value: v.value
        }));
        filterProps.onFilter = (value, record) => {
          if (record.form_data && field.name in record.form_data) {
            const item = record.form_data[field.name];
            if (item === null || item === undefined) return false;
            
            else if (typeof item == "string") {
              return item == value+'' || item.includes(value+'');
            }
            else if (Array.isArray(item)) {
              return item.includes(value+'') || item.some(v=> v.includes(value+''));
            }
            console.info('no filter match', field.name)
          }
          return false;
        }
      }
  
      // if (['art30_eesmark'].includes(field.name)) {
      //   filterProps.sorter = {
      //     multiple: 1,
      //     compare: (a, b) => {
      //       let valueA = a.form_data['art30_eesmark'||field.name];
      //       let valueB = b.form_data['art30_eesmark'||field.name];
            
      //       if (Array.isArray(valueA)) {
      //         valueA = valueA.join(', ');
      //       }
      //       if (Array.isArray(valueB)) {
      //         valueB = valueB.join(', ');
      //       }
      //       return valueA.localeCompare(valueB);
      //     },
      //   }
      //   filterProps.filterSearch = true;
      //   filterProps.defaultSortOrder = 'ascend';
      //   console.info('setting art30_eesmark conf',filterProps)
      // }
  
      return {
        // sortOrder: state.tables[tableName].sorter?.columnKey === field.name ? state.tables[tableName].sorter!.order : null,
        ...filterProps,
        render: (value: string) => renderValue(value, `${compactMode ? 'is' : 'non'}-compact`, compactMode, field, i18n.language as Language)
      };
    });

    return [
      ...firstColumns,
      ...extraColumns,
      ...lastColumns
    ];
  }, [fFields, t, compactMode, hasFilters, state.tables[tableName]]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setSearch(e.target.value);
  };

  React.useEffect(() => {
    if (!search) {
      setSearchedData(ttrData);
    }
    else if (ttrData) {
      const newFilteredList = ttrData.filter((d) =>
        d.uuid.includes(search.toLowerCase()) ||
          JSON.stringify(d.form_data)
            .toLowerCase()
            .includes(search.toLowerCase())
      )
      setSearchedData(newFilteredList);
    }
  }, [search, ttrData]);

  const tableFilters = [
    {
      key: 'search',
      label: `${t('table.labels.searchFilter', 'search')}`,
      children: (
        <Input.Search
          placeholder={t('table.labels.searchPlaceholder')}
          allowClear
          onSearch={(value) => handleSearch(value)}
          style={{ width: '100%' }}
        />
      )
    },
    {
      key: 'faculty',
      label: (
        <Typography.Text strong={(state.tables[tableName]?.filters?.faculty_code ?? []).length > 0}>
          {t('table.labels.facultyFilter', 'üksus')}
          {state.tables[tableName]?.filters?.faculty_code && ` (${(state.tables?.[tableName]?.filters?.faculty_code ?? []).length})`}
        </Typography.Text>
      ),
      children: (
        <Select
          allowClear
          mode="multiple"
          // placeholder={t('table.labels.selectCreator')}
          style={{ width: '100%' }}
          onChange={(value) => handleFacultyFilterChange(value)}
          value={state.tables?.[tableName]?.filters?.faculty_code}
        />
      )
    },
    {
      key: 'status',
      label: (
        <Typography.Text strong={(state.tables?.[tableName]?.filters?.status ?? []).length > 0}>
          {t('table.labels.statusFilter', 'staatus')}
          {state.tables?.[tableName]?.filters?.status && ` (${(state.tables?.[tableName]?.filters?.status ?? []).length})`}
        </Typography.Text>
      ),
      children: (
        <Select
          allowClear
          mode="multiple"
          placeholder={t('table.labels.selectStatus')}
          style={{ width: '100%' }}
          value={state.tables?.[tableName]?.filters?.status ?? []}
          onChange={(value) => handleStatusFilterChange(value)}
          options={statusItems.map(
            (i, idx) => ({ key:idx, text: i, value: i})
          )}
        />
      )
    },
    // {
    //   key: 'created_date_ranges',
    //   label: `${t('table.labels.dateRangeFilter', 'loodud')} (${dateRangeFilterCount})`,
    //   children: (
    //     <Space direction="vertical" size={12}>
    //       <DatePicker.RangePicker onChange={(dates, dateStrings) => handleDateRangeFilterChange(dateStrings)} />
    //     </Space>
    //   )
    // },
    {
      key: 'updated_date_ranges',
      label: `${t('table.labels.dateRangeFilter', 'kuupäeva järgi')} (${0})`,
      children: (
        <Space direction="vertical" size={12}>
          <Radio.Group
            defaultValue='created'
            options={[
              { value: 'created', label: t('', 'loodud') },
              { value: 'updated', label: t('', 'uuendatud') },
            ]}
          />
          <DatePicker.RangePicker
            onChange={(dates, dateStrings) => handleDateRangeFilterChange(dateStrings)}
          />
        </Space>
      )
    },
  ];

  const clearAll = () => {
    const data = { ...state.tables[tableName], filters: {} }
    dispatch({
      type: ActionTypes.Update_table,
      payload: { tableName, data }
    });
    
  };

  const closeFilterDrawer = () => {
    setFiltersOpen(false);
  };

  const filtersCount: number = Object.values(
    state.tables[tableName]?.filters ?? {}
  ).filter(i=>i).length;

  // Handle focus and blur events
  const handleFocus = () => setIsSearchFocused(true);

  const handleBlur = () => {
    if (!search) {
      setIsSearchFocused(false);
    }
  };

  return (
    <>
      <PageWrapper>
        <Flex>
          <Input.Search
            allowClear
            className={`search-input ${isSearchFocused || search ? 'full-width' : ''}`}
            placeholder={t("forms.placeholders.search")}
            value={search}
            onSearch={handleSearch}
            onChange={handleSearchChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            style={{
              transition: 'width .3s ease',
              width: isSearchFocused ? '100%' : 'auto'
            }}
          />
        </Flex>
        {/*
        <Row justify='space-between' style={{ margin: '1rem 0' }}>
          <Col>
            <Input.Search
              placeholder={t("forms.placeholders.search")}
              value={search}
              onSearch={handleSearch}
              onChange={handleSearchChange}
              // prefix={<SearchOutlined />}
            />
          </Col>
          <Button.Group>
            <Button
              type='primary'
              icon={<PlusOutlined />}
              onClick={() => console.log('nav or make new modal for inserting')}
            >
              {t('forms.btn.add')}
            </Button>
            <Dropdown menu={{items:[]}} trigger={['click']}>
              <Button icon={<MoreOutlined />} />
            </Dropdown>
          </Button.Group>
        </Row>
        */}
      </PageWrapper>
 
      <AppTable<Responses.TTRData>
        rowKey='uuid'
        isSelectable={true}
        tableName={tableName}
        tableTitle='table.title.projects.art30'
        isAdmin={true}
        loading={isLoading}
        dataSource={searchedData}
        extraColumns={columns}
        sticky={{ offsetHeader: 64 }}
        scroll={{ x: 400 }}
        onConfirm={handleEntityConfirms}
        isFilterable={true}
        headerButtons={[
          <Badge
            key={'open-filters'}
            size="small"
            count={filtersCount}
            style={{ zIndex: 999 }}
          >
            <Button
              shape="round"
              icon={<FilterOutlined style={{fontSize: '.9em'}}/>}
              onClick={() => setFiltersOpen(true)}
              styles={{
                icon: {
                  color: filtersCount ? token.colorPrimary : 'inherit'
                }
              }}
            >
              {t('forms.btn.filters')}
            </Button>
          </Badge>

        ]}
        // onConfigChange={({sorter})}
        className='ak-table admin-table'
        rowClassName={compactMode ? ' compact-row ' : ''}
        expandable={{
          rowExpandable: (record) => record.form_data && Object.keys(record.form_data).length > 0,
          expandedRowRender: (record) => (
            <ExpandRowContent
              key={record.uuid}
              record={record}
              onEdit={handleEntityChange}
              onAdminEdit={handleAdminEntityChange}
              formFields={fFields}
              isAdmin={true}
              onDelete={console.log}
            />
          ),
          columnWidth: 48,
          expandIcon: ({expanded, onExpand, record}) => expanded
            ? <DownSquareFilled onClick={(e)=> onExpand(record, e)} style={{fontSize: '1.4rem'}} />
            : <RightSquareOutlined onClick={(e)=> onExpand(record, e)} style={{fontSize: '1.4rem'}} />
        }}
        style={{ width: '100%'}}
      />

      <Drawer
        key='filters'
        placement='right'
        open={filtersOpen}
        onClose={closeFilterDrawer}
        closable={false}
        title={
          <Space>
            <FilterTwoTone />
            {t('table.title.tableFilters')}
          </Space>
        }
        style={{ width: '100%', height: '100%' }}
        styles={{
          body: {
            position: 'relative',
            display: 'block',
            padding: 0,
            width: '100%'
          }
        }}
        footer={
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <Button type='text' onClick={closeFilterDrawer}>
              {t('buttons.close')}
            </Button>
            <Button type='link' onClick={clearAll}>
              {t('forms.btn.reset')}
            </Button>
          </Space>
        }
      >            
        <Collapse
          bordered={false}
          defaultActiveKey={['search', ...Object.keys(state.tables?.[tableName]?.filters ?? {})]}
          // onChange={(key: string | string[]) => console.log(key)}
          expandIconPosition='end'
          items={tableFilters}
        />

        <Flex
          vertical
          gap={'1rem'}
          justify='center'
          align='center'
          style={{
            padding: '1rem 1.4rem'
          }}
        >
          <Button
            block
            icon={<FilterFilled />}
            onClick={clearAll}
          >
            {t('table.texts.filterConfirm')}
          </Button>
          <Tooltip
            title={t('forms.btn.clearAllFilters')}
            placement='left'
          >
            <Button
              block
              type='dashed'
              icon={<ClearOutlined />}
              onClick={clearAll}
            >
              {t('forms.btn.clearAllFilters')}
            </Button>
          </Tooltip>
        </Flex>
      </Drawer>

      <Modal
        title={t(`forms.titles.${action}Article30`)}
        open={modalOpen}
        onOk={handlePost}
        okText={t(`forms.btn.${action === 'new' ? 'create' : 'update'}`)}
        cancelText={t('forms.btn.cancel')}
        confirmLoading={isLoading}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <Typography>
          {selectedForm.current?.description}
        </Typography>
        
        <Divider />

        <DynamicFormModal
          formData={formsData[0] ?? {fields:[]}}
          initialValues={selectedItem.current}
          //@ts-ignore
          options={undefined}
          isAdmin={true}     
          facultyOptions={faculties}
          action={action}
          onFinish={handlePost} 
          onCancel={handleCancel}
          onDelete={handleDataDelete}
        />

      </Modal>

      <Modal
        title={t(`forms.titles.${action}Article30`)}
        open={adminModalOpen}
        confirmLoading={isLoading}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <Typography>{selectedForm.current?.description}</Typography>
        <Divider />
        <AdminDataUpdate
          initialValues={selectedItem.current}
          formOptions={formsData}
          isResearchForm={false}
          callback={console.log}
        />
      </Modal>
    </>
  );
};
